import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PostFooterMeta from "../../components/post-footer-meta"
import Img from "gatsby-image"

const SinglePost = props => {
    const {
        pageContext: {
            id,
            postId,
            title,
            excerpt,
            content,
            featuredImage,
            uri,
            date,
            author,
            categories,
            tags,
            prev,
            next,
        },
    } = props

    return (
        <Layout cssClasses="single single-post">
            <SEO
                title={title}
                description={excerpt}
                image={featuredImage.sourceUrl}
            />
            <article
                data-id={id}
                id={`post-${postId}`}
                className={`post-${postId} post type-post status-publish format-standard hentry category-react tag-accessibility tag-gatsby entry`}
            >
                {featuredImage && (
                    <div className="post-image">
                        {featuredImage.imageFile !== null &&
                            Object.keys(featuredImage.imageFile).indexOf(
                                "childImageSharp"
                            ) !== -1 && (
                                <Img
                                    fluid={
                                        featuredImage.imageFile.childImageSharp
                                            .fluid
                                    }
                                />
                            )}
                    </div>
                )}

                <header className="post-header entry-header">
                    <h1
                        className="entry-title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                </header>

                <div
                    className="entry-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                {/* .entry-content */}

                <PostFooterMeta
                    uri={uri}
                    date={date}
                    author={author}
                    categories={categories}
                    tags={tags}
                />
            </article>
            {/* #post-${ID} */}

            <div className="about-the-author">
                <h4 className="about-the-author-title">About the Author</h4>
                <div className="about-the-author-box">
                    <div className="about-the-author-image">
                        <img src={author.avatar.url} alt={author.name} />
                    </div>
                    <div className="about-the-author-content">
                        <h3 className="about-the-author-name">{author.name}</h3>
                        <p>{author.description}</p>
                        {/* <ul className="author-social">
                            <li>
                                <a href="#">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#444444"
                                    >
                                        <path
                                            d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                                            fill="#444444"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#444444"
                                    >
                                        <path
                                            d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                                            fill="#444444"
                                        />
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#444444"
                                    >
                                        <path
                                            d="M 8 3 C 5.243 3 3 5.243 3 8 L 3 16 C 3 18.757 5.243 21 8 21 L 16 21 C 18.757 21 21 18.757 21 16 L 21 8 C 21 5.243 18.757 3 16 3 L 8 3 z M 8 5 L 16 5 C 17.654 5 19 6.346 19 8 L 19 16 C 19 17.654 17.654 19 16 19 L 8 19 C 6.346 19 5 17.654 5 16 L 5 8 C 5 6.346 6.346 5 8 5 z M 17 6 A 1 1 0 0 0 16 7 A 1 1 0 0 0 17 8 A 1 1 0 0 0 18 7 A 1 1 0 0 0 17 6 z M 12 7 C 9.243 7 7 9.243 7 12 C 7 14.757 9.243 17 12 17 C 14.757 17 17 14.757 17 12 C 17 9.243 14.757 7 12 7 z M 12 9 C 13.654 9 15 10.346 15 12 C 15 13.654 13.654 15 12 15 C 10.346 15 9 13.654 9 12 C 9 10.346 10.346 9 12 9 z"
                                            fill="#444444"
                                        />
                                    </svg>
                                </a>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>

            <nav className="navigation post-navigation" role="navigation">
                <div className="nav-links">
                    {prev && (
                        <div className="nav-previous">
                            <Link to={`/blog/${prev.uri}`} rel="prev">
                                <span className="meta-nav" aria-hidden="true">
                                    Previous Post
                                </span>{" "}
                                <div
                                    className="post-title"
                                    dangerouslySetInnerHTML={{
                                        __html: prev.title,
                                    }}
                                />
                            </Link>
                        </div>
                    )}
                    {next && (
                        <div className="nav-next">
                            <Link to={`/blog/${next.uri}`} rel="next">
                                <span className="meta-nav" aria-hidden="true">
                                    Next Post
                                </span>{" "}
                                <div
                                    className="post-title"
                                    dangerouslySetInnerHTML={{
                                        __html: next.title,
                                    }}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </nav>
        </Layout>
    )
}

export default SinglePost
